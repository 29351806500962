export const BANKLIST = [
  {code: 'AIIP', name: 'Al-Amanah Islamic Investment Bank of the Philippines'},
  {code: 'AUBK', name: 'Asia United Bank Corporation (AUB)'},
  {code: 'ANZB', name: 'Australia and New Zealand Banking Group (ANZ)'},
  {code: 'BNOR', name: 'BDO Unibank, Inc. (BDO)'},
  {code: 'BOPB', name: 'BDO Private Bank'},
  {code: 'BOFA', name: 'Bank of America, N.A.'},
  {code: 'BKCH', name: 'Bank of China - Manila Branch'},
  {code: 'PABI', name: 'Bank of Commerce'},
  {code: 'BOPI', name: 'Bank of the Philippine Islands (BPI)'},
  {code: 'BKKB', name: 'Bangkok Bank Co. Ltd.'},
  {code: 'UWCB', name: 'Cathay United Bank Co. Ltd. - Manila Branch'},
  {code: 'CCBC', name: 'Chang Hwa Commercial Bank LTD - Manila Branch'},
  {code: 'CHBK', name: 'China Banking Corporation (Chinabank)'},
  {code: 'CTCB', name: 'Chinatrust Philippines Commercial Bank Corporation (Chinatrust)'},
  {code: 'CIPH', name: 'CIMB Bank Philippines Inc.'},
  {code: 'CITI', name: 'Citibank Philippines'},
  {code: 'DBPH', name: 'Development Bank of the Philippines (DBP)'},
  {code: 'DEUT', name: 'Deutsche Bank'},
  {code: 'EWBC', name: 'East West Banking Corporation (EastWest Bank)'},
  {code: 'FCBK', name: 'First Commercial Bank Manila'},
  {code: 'HNBK', name: 'Hua Nan Commercial Bank Ltd. Manila'},
  {code: 'IBKO', name: 'Industrial Bank of Korea Manila Branch'},
  {code: 'INGB', name: 'ING Group N.V.'},
  {code: 'CHAS', name: 'JPMorgan Chase & Co. (JPMorgan Chase)'},
  {code: 'KOEX', name: 'KEB Hana Bank - Manila Branch'},
  {code: 'TLBP', name: 'Land Bank of the Philippines (LBP)'},
  {code: 'MBBE', name: 'Maybank Philippines, Inc.'},
  {code: 'ICBC', name: 'Mega International Commercial Bank Co. LTD'},
  {code: 'MBTC', name: 'Metropolitan Bank and Trust Company (Metrobank)'},
  {code: 'MHCB', name: 'Mizuho Bank, Ltd. Manila Branch'},
  {code: 'BOTK', name: 'MUFG Bank, Ltd.'},
  {code: 'CPHI', name: 'Philippine Bank of Communications (PBCom)'},
  {code: 'PNBM', name: 'Philippine National Bank (PNB)'},
  {code: 'PHTB', name: 'Philippine Trust Company (Philtrust Bank)'},
  {code: 'PHVB', name: 'Philippine Veterans Bank (PVB)'},
  {code: 'RCBC', name: 'Rizal Commercial Banking Corporation (RCBC)'},
  {code: 'ROBP', name: 'Robinsons Bank Corporation (RBank)'},
  {code: 'SETC', name: 'Security Bank Corporation (Security Bank)'},
  {code: 'SHBK', name: 'Shinhan Bank - Manila Branch'},
  {code: 'SCBL', name: 'Standard Chartered Bank Philippines'},
  {code: 'SMBC', name: 'Sumitomo Mitsui Banking Corporation Manila Branch'},
  {code: 'HSBC', name: 'The Hongkong and Shanghai Banking Corporation Limited (HSBC)'},
  {code: 'TODG', name: 'Tonik Bank'},
  {code: 'UBPH', name: 'Union Bank of the Philippines, Inc. (Unionbank)'},
  {code: 'UCPB', name: 'United Coconut Planters Bank (UCPB)'},
  {code: 'UOVB', name: 'United Overseas Bank Limited Manila Branch (UOB)'}
];
